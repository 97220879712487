<template>
  <div class="fill-height d-flex flex-column justify-space-between">
    <v-container class="d-flex flex-column align-center">
      <v-card-title
        class="secondary--text font-weight-bold flex-row justify-center"
      >
        <span> {{ t("title") }}</span>
      </v-card-title>
      <v-card-subtitle class=" flex text-center font-weight-medium">
        {{ t("subtitle") }}
      </v-card-subtitle>
      <v-tooltip
        color="primary"
        :disabled="!showTooltip"
        content-class="mx-4"
        v-model="showTooltip"
        bottom
        nudge-top="90"
      >
        <template v-slot:activator="{ on, attrs }">
          <CardTransferType v-bind="attrs" v-on="on"  />
        </template>
        <span
          class="d-flex text-center white--text subtitle-2 font-weight-bold"
          >{{ tooltipText }}</span
        >
      </v-tooltip>
      <v-container>
        <v-row>
          <v-col cols="12" class="d-flex flex-row justify-space-between">
            <div>
              <div class="primary--text font-weight-bold subtitle-2">
                {{ t("adults") }}
              </div>
              <div class="subtitle-2 bluegrey--text">+13 {{ t("year") }}</div>
            </div>
            <v-spacer></v-spacer>
            <BtnCounter
              :disabled="disabledBtnCounter"
              :disabled-increment="isSharedType || isMaxCapacity"
              :disabled-decrement="isSharedType || disabledDecrement"
              @input="update"
              @disabled="showDisabledInfo"
              v-model="form.adults"
            />
          </v-col>
          <v-col cols="12" class="d-flex flex-row justify-space-between">
            <div>
              <div class="primary--text font-weight-bold subtitle-2">
                {{ t("children") }}
              </div>
              <div class="subtitle-2 bluegrey--text">2-12 {{ t("year") }}</div>
            </div>
            <v-spacer></v-spacer>
            <BtnCounter
              :disabled="disabledBtnCounter"
              :disabled-increment="isSharedType || isMaxCapacity"
              :disabled-decrement="isSharedType || disabledDecrement"
              @input="update"
              @disabled="showDisabledInfo"
              v-model="form.children"
            />
          </v-col>
          <v-col cols="12" class="d-flex flex-row justify-space-between">
            <div>
              <div class="primary--text font-weight-bold subtitle-2">
                {{ t("infants") }}
              </div>
              <div class="subtitle-2 bluegrey--text">0-23 {{ t("month") }}</div>
            </div>
            <v-spacer></v-spacer>
            <BtnCounter
              :disabled="disabledBtnCounter"
              :disabled-increment="isSharedType || isMaxCapacity"
              :disabled-decrement="isSharedType || disabledDecrement"
              @input="update"
              @disabled="showDisabledInfo"
              v-model="form.infants"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-container class="mb-3">
      <v-row dense class="flex-column align-center">
        <v-col cols="6"
          ><v-btn color="primary" block rounded @click="confirm()">{{
            $t("global.btnConfirm")
          }}</v-btn></v-col
        >
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapFields } from "@/helpers/mapFields";
import BtnCounter from "@/components/btn/BtnCounter";
import CardTransferType from "@/components/card/CardTransferType";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    BtnCounter,
    CardTransferType
  },
  data: () => ({
    form: {},
    showTooltip: false,
    tooltipText: ""
  }),
  computed: {
    ...mapGetters("service", ["getBasePath", "inputDisableType"]),
    ...mapFields({
      fields: ["reservation", "serviceType", "id"],
      base: "checkinModel",
      mutation: "SET_UPDATE_MODEL",
      module: "service"
    }),
    isSharedType: vm => vm.serviceType?.serviceType === "shared",
    disabledBtnCounter: vm => vm.inputDisableType === "aftertime",
    isMaxCapacity: vm => {
      const max = vm.serviceType?.maxPax || 1;
      const sum =
        vm.reservation.adults +
        vm.reservation.children +
        vm.reservation.infants;
      return sum >= max;
    },
    disabledDecrement: vm => {
      const sum =
        vm.reservation.adults +
        vm.reservation.children +
        vm.reservation.infants;
      return sum <= 1;
    }
  },
  created() {
    this.form = { ...this.reservation };
  },
  watch: {
    id() {
      this.form = { ...this.reservation };
    }
  },
  methods: {
    ...mapActions("service", ["_updateService"]),
    showDisabledInfo(/* type */) {
      if (this.isSharedType) {
        this.showTooltip = true;
        this.tooltipText = this.t("tooltip.shared");
      } else if (this.isMaxCapacity) {
        this.showTooltip = true;
        this.tooltipText = this.t("tooltip.max");
      }
      setTimeout(() => {
        this.showTooltip = false;
      }, 4000);
    },
    confirm() {
      this._updateService({
        reservation: this.reservation
      }).then(() => this.$router.push(this.getBasePath("/confirmation")));
    },
    update() {
      this.reservation = {
        ...this.reservation,
        ...this.form
      };
    },
    t(key, options = {}) {
      return this.$t(`section.passenger.${key}`, options);
    }
  }
};
</script>
